import {useState, useEffect} from 'react'
import axios from 'axios'

import Slider from 'components/slider/slider'
import Link from 'next/link'
import Heading from 'components/heading/heading'
import styles from './lietuva.module.scss'

export default function HomeCategories() {
    const [data, setData] = useState()
    const [hide, setHide] = useState()

    useEffect(() => {
        const go = async () => {
            const data = await axios.get('https://api.lietuviskapreke.lt/v1.0/home-atributika')
            if (data?.data[0] && data?.data[0].length) {
                setData(data.data[0])
            } else {
                setHide(true)
            }
        }
        go()
    },[])

    if (hide) return null

    if (!data?.length) {
        return (
            <Slider virtual>
                <Heading tag="h2" size="30"></Heading>
            </Slider>
        )
    }

    return (
        <>
        {data.map((block, k)=>(
            <Slider key={k} products={block.products} url={block?.url} navs>
                <Heading tag="h2" className={styles.heading} size="30">
                    {block.url ? <Link href={block.url}>{block.name}</Link> : block.name}
                </Heading>
            </Slider>
        ))}
        </>
    )
}
