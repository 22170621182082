import Heading from 'components/heading/heading'
import Link from 'next/link'
import styles from './selections.module.scss'
import Image from 'components/image/image'

const data = [
    { id: 1, url: '/dovanos/jai', name: 'Dovanos JAI', image: '/img/mo.jpg' },
    { id: 3, url: '/dovanos/jam', name: 'Dovanos JAM', image: '/img/vyr.jpeg' },
    { id: 2, url: '/dovanos/vaikams', name: 'Dovanos vaikams', image: '/img/vaika.jpeg' },
    { id: 4, url: '/k/specialus-pasiulymai?page=1&hasDiscount=1', name: 'Nuolaidos', image: '/img/nuolaidos.jpg' },
    { id: 5, url: '/temos/personalizuojamos', name: 'Personalizuojamos dovanos', image: '/img/person2.jpeg' },
    { id: 6, url: '/k/vestuves', name: 'Vestuvės', image: '/img/wedding.jpeg' },
    { id: 7, url: '/k/krikstynos', name: 'Krikštynos', image: '/img/krikstynos.jpeg' },
    {
        id: 8,
        url: '/k/specialus-pasiulymai?freeShipping=1',
        name: 'Nemokamas pristatymas',
        image: '/img/nemokamas-pristatymas.jpg'
    }
]

export default function Selections() {
    
    return (
        <div className="mb72_">
            <Heading tag="h2" size="30" className="mb36">
                Dovanų kategorijos ir pasiūlymai
            </Heading>
            <div className={styles.scroll}>
                <div className={styles.container}>
                    {data.map(item => (
                        <div key={item.id} className={styles.card}>
                            <div>
                                <div key={item.id} className={styles.image}>
                                    <Image
                                        src={item.image}
                                        alt={item.name}
                                        layout="responsive"
                                        objectFit="cover"
                                        width={600}
                                        height={346}
                                    />
                                    <Link href={item.url}>
                                        <a aria-label={item.name}></a>
                                    </Link>
                                </div>
                                <Heading tag="h3" size="24" className="mb24">
                                    <Link href={item.url}>
                                        <a>{item.name}</a>
                                    </Link>
                                </Heading>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
