import {useState, useEffect} from 'react'
import axios from 'axios'

import Slider from 'components/slider/slider'
import Link from 'next/link'
import Heading from 'components/heading/heading'
import styles from './lietuva.module.scss'

export default function HomeCategories() {
    const [data, setData] = useState()

    useEffect(() => {
        const go1 = async () => {
            const data = await axios.get('https://api.lietuviskapreke.lt/v1.0/home-groups')
            
            if (data?.data[0]) {
                setData(data.data)
            }
        }
        go1()
    },[])


    if (!data?.length) {
        return null
        // return (
        //     <Slider virtual>
        //         <Heading tag="h2" size="30"></Heading>
        //     </Slider>
        // )
    }

    return (
        <>
        {data.map((block, k)=> !!block.products?.length && (
            <Slider key={k} products={block.products} url={block.url} navs>
                <Heading tag="h2" className={styles.heading} size="30">
                    <Link href={block.url}>{block.name}</Link>
                </Heading>
            </Slider>
        ))}
        </>
    )
}
