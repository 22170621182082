import React from 'react'
import Link from 'next/link'
import { useState } from 'react'

import Svg from 'components/svg/svg'
import styles from './features.module.scss'
import { getRoute } from 'utils/helpers'
import useAppState from 'utils/state'
import Heading from 'components/heading/heading'
import { cn } from 'utils/helpers'

export default function Features({ tag = 'h2', christmas, all = false }) {
    const { features } = useAppState()
    const [showMore, setShowMore] = useState(false)
    // Ieškai dovanos, kuri būtų daugiau nei daiktas?
    // <br />
    // Atrask dovaną su istorija!
    return (
        <section className="mb72">
            <Heading tag={tag} size="30" className={cn(styles.heading, 'mb48')}>
                {christmas ? (
                    <>Atrask dovaną su istorija!</>
                ) : (
                    // 'Unikalus pirkėjas vertas unikalių pasiūlymų'
                    'Skatiname atsakingą prekybą'
                )}
            </Heading>
            <div className={cn(styles.features, !showMore && !all && styles.hidden, all && styles.all)}>
                {features.length === 0 && (
                    <div className={cn(styles.feature, styles.virtual)}>
                        <Svg name="x" className={styles.icon} />
                        <div className={styles.name} />
                    </div>
                )}
                {features.map((item, k) => {
                    const name =
                        item.slug === 'idarbinant-socialiai-jautrias-visuomenes-grupes'
                            ? 'Įdarbinant jautrias grupes'
                            : item.name
                    return (
                        <div
                            key={k}
                            className={cn(
                                styles.feature,
                                christmas && styles.christmasIcon,
                                k > 7 && styles.more8,
                                k > 5 && styles.more6,
                                k > 3 && styles.more4
                            )}
                        >
                            <Svg
                                name={`feature-${item.slug}`}
                                className={cn(styles.icon, styles[item.slug.replace(/-/g, '')])}
                            />
                            <div className={styles.name}>{name}</div>
                            <Link href={getRoute('feature', item)}>
                                <a aria-label={item.name}></a>
                            </Link>
                        </div>
                    )
                })}
                {!showMore && !all && (
                    <div className={styles.bottom}>
                        <div className={styles.more} onClick={() => setShowMore(true)}>
                            Rodyti daugiau
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}
