// import dynamic from 'next/dynamic'
import Wrap from './../wrap'
import TextareaAutosize from 'react-textarea-autosize'

// const TextareaAutosize = dynamic(() => import('react-textarea-autosize'))

export default function Textarea(props) {
    const { onChange, required, value, name, readOnly, maxLength, minLength, placeholder } = props

    const inputProps = {
        autoComplete: 'off',
        autoCorrect: 'off',
        autofill: 'off',
        autoCapitalize: 'off',
        spellCheck: 'false',
        maxLength: maxLength || 10000,
        placeholder,
        onChange,
        required,
        value: value || value === 0 ? value : '',
        name,
        readOnly
    }

    if (minLength) {
        inputProps.pattern = `.{${minLength},}`
        inputProps.title = '3 characters minimum'
    }

    return (
        <Wrap {...props}>
            <TextareaAutosize {...inputProps} />
        </Wrap>
    )
}
