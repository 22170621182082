import { useEffect, useState, useRef } from 'react'
import styles from './eureka.module.scss'
import Heading from 'components/heading/heading'
import Textarea from 'components/input/textarea/textarea'
import Btn from 'components/btn/btn'
import { cn } from 'utils/helpers'
import Svg from 'components/svg/svg'
import useAppState from 'utils/state'
import Card from 'components/card/card'
import Image from 'components/image/image'
import { get } from 'utils/api'

export default function Eureka() {
    const [text, setText] = useState('')
    const [anim, setAnim] = useState(false)
    const done = useRef(false)
    const [left, setLeft] = useState(3)
    const [reveal, setReveal] = useState(false)
    const [products, setProducts] = useState([])
    const { showLoader } = useAppState()

    useEffect(() => {
        const check = async () => {
            const response = await get('eureka-left')
            setLeft(response.data.left)
            setReveal(response.data.left > 0)
        }

        check();
    },[])

    useEffect(() => {
        if (done.current) {
            return;
        }

        if (text.length < 30 && text.length > 3 && !anim) {
            setAnim(true)
            setTimeout(() => {
                done.current = true
                setAnim(false)
            }, 3000);
        }
    },[text, anim])

    const handleClick = async () => {
        showLoader(true)
        const response = await get('eureka', { text })
        showLoader(false)

        if (response.status == 200) {
            setLeft(left - 1)
        }
        
        if (response?.data?.products) {
            setProducts(response.data.products)
        }
    }

    const handleAgain = () => {
        setProducts([])
        setLeft(left - 1)
    }

    if (!reveal) {
        return null;
    }

    return (
        <div className={cn(styles.container, 'mb72', text.length < 30 && styles.invalid, text.length < 4 && styles.tmp)}>
            <div className={styles.content}>
                <Svg className={styles.top} name="paper-top" />
                <Svg className={styles.bottom} name="paper-bottom" />
                <div className={styles.wrap}>
                    {!products.length && (
                        <div className={styles.sides}>
                            <div className={styles.star1}>
                                <Image
                                    src="/img/star.png"
                                    alt=""
                                    layout="responsive"
                                    objectFit="contain"
                                    width={185}
                                    height={200}
                                />
                            </div>
                            <div className={styles.star2}>
                                <Image
                                    src="/img/star.png"
                                    alt=""
                                    layout="responsive"
                                    objectFit="contain"
                                    width={185}
                                    height={200}
                                />
                            </div>
                            <div className={styles.star3}>
                                <Image
                                    src="/img/star.png"
                                    alt=""
                                    layout="responsive"
                                    objectFit="contain"
                                    width={185}
                                    height={200}
                                />
                            </div>
                            <div className={styles.part}>
                                <Heading size="30" tag="h2">
                                    Atrasti, ko ieškai, padės dirbtinis intelektas!
                                </Heading>
                                <p>
                                    Kuo tiksliau aprašyk, ko ieškai, ir dirbtinis intelektas tau atrinks geriausius pasiūlymus. Kiekvieną dieną turi galimybę išbandyti 3 kartus.
                                </p>
                                <div className={styles.left}>
                                    Liko bandymų: {left}
                                </div>
                            </div>
                            <div className={styles.part}>
                                <Textarea
                                    name="text"
                                    placeholder=""
                                    value={text}
                                    maxLength={400}
                                    onChange={e => setText(e.target.value)}
                                />
                                <Btn
                                    style="green"
                                    onClick={handleClick}
                                >Eureka!</Btn>
                                {text.length < 40 && <div className={cn(styles.info, anim && styles.anim)}>
                                    {`Užklausa turi būti ilgesnė (${text.length}/30)`}
                                </div>}
                            </div>
                        </div>
                    )}
                    {!!products.length && (
                        <div className={styles.results}>
                            <Heading size="30" tag="h2" className="mb48">
                                Dirbtinis intelektas tau rado šias prekes:
                            </Heading>
                            <div className="ais-Hits-list">
                                {products.map(hit => <Card key={hit.code} hit={hit} hideRating={true} />)}
                            </div>
                            {left > 0 && <Btn style="green" onClick={handleAgain}>Bandyti dar kartą</Btn>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
